if (module.hot) module.hot.accept();
import "bootstrap";
import '../../vendor/cookieconsent.css';
import '../styles/app.scss';


import primaryMenu from 'components/primary-menu/';
import actuaelt from 'components/actuaelt/';
import viden from 'components/viden/';
import maerkesager from 'components/maerkesager/';
import twitterFeed from 'components/twitter-feed';
import pattern from 'components/pattern';

$(() => {
    // create select box action (many dropdown)
    $('.dropdown-item a').click(function(){
        var data = $(this).data('value');
        var button = $(this).parents('.dropdown-menu').siblings('button.btn-dropdown');
        button.text(data);
    });



	primaryMenu();
	actuaelt();
	viden();
	maerkesager();
	twitterFeed();
});
