export default function lineChart2() {
    function hexToRgbA(hex){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            // return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
            return [(c>>16)&255, (c>>8)&255, c&255].join(',');
        }
        // throw new Error('Bad Hex');
        return [(c>>16)&0, (c>>8)&0, c&0].join(',');
    };
    $(document).ready(function(){
        if($('.line-chart').length > 0) {
            var chartData = JSON.parse($('.line-chart .chart-data').val());
            // console.log("chartData", chartData);
            var placeHolder = document.getElementById('lineChart2').getContext('2d');
            var lastDataset = buildDataset(chartData.data.datasets);
            function buildDataset(arrObject) {
                arrObject.forEach(function callbacks(object, index) {
                    object.pointBackgroundColor = 'rgba(255,255,255,1)';
                    object.pointHoverBackgroundColor = object.borderColor;
                    object.pointHoverBorderColor = 'rgba(' + hexToRgbA(object.borderColor) + ',0.4)';
                    object.pointHoverBorderWidth = 10;
                });
                return arrObject;
            }
            let cData = {
                labels: chartData.data.labels,
                datasets: lastDataset,
            };
            let cOptions = {
                title: {
                    display: false,
                },
                spanGaps: true,
                scales: {
                    xAxes: [{
                        type: 'category',
                        position: 'bottom',
                    }],
                    yAxes: [{
                        // type: 'linear',
                        ticks: {
                            // beginAtZero: chartData.options.scales.yAxes[0].ticks.beginAtZero,
                            beginAtZero: true,
                            maxRotation: 90
                        }
                    }],
                },

                legend: {
                    // position: 'top'
                    // rtl: true,
                    align: 'end',
                    labels: {
                        boxWidth: 12,
                        fontSize: 14,
                        padding: 20,
                        usePointStyle: true,
                    }
                },
                tooltips: {
                    enabled: false,
                    titleFontSize: 14,
                    titleFontColor: '#01BEE0',
                    bodyFontSize: 12,
                    displayColors: false,
                    callbacks: {
                        title: function(tooltipItem, data) {
                            var lineIndex = tooltipItem[0].datasetIndex;
                            return data.datasets[lineIndex].label;
                        },
                        label: function(tooltipItem, data) {
                            // console.log("tooltipItem", tooltipItem);
                            let curVal = tooltipItem.yLabel;
                            let curText = data.datasets[tooltipItem.datasetIndex].tooltipText[tooltipItem.index] === undefined ? '' : data.datasets[tooltipItem.datasetIndex].tooltipText[tooltipItem.index];
                            // return curVal + ' ' +curText;
                            return curText;
                        },
                    },
                    custom: function(tooltipModel) {
                        // console.log("tooltipModel", tooltipModel);
                        var tooltipEl = document.getElementById('multiLineChart-tooltip');
        				var tooltipCaret = document.querySelectorAll('#multiLineChart-tooltip .caret')[0];

                        // Create element on first render
                        if (!tooltipEl) {
        	                tooltipEl = document.createElement('div');
        	                tooltipEl.id = 'multiLineChart-tooltip';
        	                tooltipEl.classList.add('chartjs-tooltip');
        	                tooltipEl.innerHTML = '<table width="100%"></table><div class="caret"></div>';

        	                document.getElementById("lineChart2").parentElement.appendChild(tooltipEl);
                            tooltipCaret = document.querySelectorAll('#multiLineChart-tooltip .caret')[0];
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            tooltipCaret.style.opacity = 0;
                            return;
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }
                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);
                            var innerHtml = '<thead>';
                            titleLines.forEach(function(title, i) {
                                var colors = tooltipModel.labelColors[i];
                                // console.log("colors", colors);
                                var span = '<span style="color:' + colors.borderColor + '">';
                                innerHtml += '<tr><th>' + span + title + '</span></th></tr>';
                            });
                            innerHtml += '</thead><tbody>';
                            bodyLines.forEach(function(body, i) {
                                innerHtml += '<tr><td>' + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';
                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }



                        //re position label with length larger than chart width
                        if(tooltipModel.width > 198){
                            tooltipModel.width = 198;
                            var distanceX = tooltipModel.dataPoints[0].x - 198 + 20;
                            if(distanceX >= 0) {
                                tooltipModel.xAlign ='right';
                            }else {
                                tooltipModel.xAlign ='left';
                            }
                        }

                        if(tooltipModel.dataPoints[0].x > ($('#lineChart2').outerWidth()/2)){
                            tooltipModel.x = tooltipModel.dataPoints[0].x - $('#lineChart2').siblings('.chartjs-tooltip').outerWidth() - 10;
                        }else {
                            tooltipModel.x = tooltipModel.dataPoints[0].x + 10;
                        }


                        // Set caret Position
                        tooltipEl.classList.remove('center', 'middle', 'left', 'top', 'right', 'bottom');
                        if (tooltipModel.xAlign) {
                            tooltipEl.classList.add(tooltipModel.xAlign);
                        } else {
                            tooltipEl.classList.add('right');
                        }
                        // if (tooltipModel.yAlign){
                        //     if (tooltipModel.yAlign == 'center') {
                        //         tooltipModel.yAlign =='middle';
                        //     }else {
                        //         tooltipEl.classList.add(tooltipModel.yAlign);
                        //     }
                        // }else {
                        //     tooltipEl.classList.add('middle');
                        // }



                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();
                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipCaret.style.opacity = 1;
                        tooltipEl.style.padding = tooltipModel.yPadding + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';

                        tooltipEl.style.left = tooltipModel.x + 'px';
                        tooltipEl.style.top = tooltipModel.y + 'px';
                        
                        tooltipCaret.style.left = tooltipModel.caretX - tooltipModel.x + 'px';
                        tooltipCaret.style.top = tooltipModel.caretY - tooltipModel.y + 'px';

                    }
                },
            };
            var lineChart2 = new Chart(placeHolder, {
                type: 'line',
                data: cData,
                options: cOptions,
            });
        }
    });
}