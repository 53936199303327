var jQueryBridget = require('jquery-bridget');
var Masonry = require('masonry-layout');
var $ = require('jquery');
var imagesLoaded = require('imagesloaded');
// make Masonry a jQuery plugin
jQueryBridget('masonry', Masonry, $);

export default function () {

    let maxIds = "";
    let usernames = "";
    let tf;
    let $twitterFeedContainer = $('.tf-feeds');
    let isFirstLoad = true;
    imagesLoaded.makeJQueryPlugin($);

    $(document).ready(function () {
        if ($('.twitter-feed .tf-feeds').length > 0) {

            usernames = $twitterFeedContainer.data('usernames');
            tf = $twitterFeedContainer.masonry({
                itemSelector: '.tf-feed',
                // columnWidth: 200
            });

            $('.twitter-feed').on('click', '#twitter-load-more', function () {
                if (usernames != "") {
                    feedTweets();
                }
            });
            if (usernames != "") {
                feedTweets(true);
            }
        }
    });

    function showHideLoading(selector, action) {
        if(action=='add') {
            if($(selector + '.common-loading').length > 0) {
                return true;
            }else {
                $(selector).addClass('common-loading');
            }
        }else {
            if($(selector + '.common-loading').length > 0) {
                $(selector).removeClass('common-loading');
            }
        }
        return false;
    }


    function feedTweets(isPageLoad) {
        var currentPage =  $twitterFeedContainer.data("page-id");
		var number =  $twitterFeedContainer.data("number-tweets");
		number = $(window).width() < 768? 1 : number;

		var fetchedUsers = (isPageLoad &&  $(window).width() < 768)? usernames.split(',')[0]: usernames;
        //  showHideLoading(true);
        var alreadyCall = showHideLoading('#twitter-load-more', 'add');
        if(!alreadyCall) {
            $.ajax({
                url: "/umbraco/surface/TwitterFeed/Feed",
                type: 'GET',
                data: {usernames: fetchedUsers, maxIds: maxIds, currentPage: currentPage, numberOfTweets: number},
                dataType: 'json',
                success: function (data) {
                    
                    if (data != null) {
                        if (data.status == 200) {
                            if (data.dataResult != "") {
                                var tweets = JSON.parse(data.dataResult);
                                maxIds = data.ids;

                                var listFeeds = renderFeeds(tweets);

                                tf.append(listFeeds).masonry("reloadItems").masonry("layout").imagesLoaded( function() {
                                    tf.masonry("reloadItems").masonry("layout");
                                });

                            }
                        } else {
                            console.log("Error when get data");
                        }
                    }
                    //  showHideLoading(false);
                    showHideLoading('#twitter-load-more','remove');
                }
            });
        }
    }

    function renderFeeds(tweetFeeds) {
        var html = '';

        if (tweetFeeds.length > 0) {
            for (var i = 0; i < tweetFeeds.length; i++) {
                html += renderTweetItem(tweetFeeds[i]);
            }
        } else {
            html = "<li class='row'><div class='col-12 empty-article'>Der er ingen resultater på den ønskede søgning.</div></li>";
        }

        return html;
    }

    function renderTweetItem(tweetItem) {
        var retweetedUser = "";
        if(tweetItem.hasRetweeted){
            var retweetedUser = `<li class="r-tweet">
                                <span class="icon icon-retweet"></span>
                                <a href="${tweetItem.retweetedUser.url}" target="_blank"><span class="text">${tweetItem.retweetedUser.username} retweetede</span></a>
                            </li>`;

        }

        var html = `<div class="tf-feed" data-id="${tweetItem.id}">
                    <div class="top-cart">
                        <div class="avatar">
                            <img src="${tweetItem.owner.image}" alt="">
                        </div>
                        <ul class="right-info">
                           ${retweetedUser}
                            <li class="r-name">
                              <a href="${tweetItem.owner.url}">
                                <span class="name">
                                      ${tweetItem.owner.name}
                                </span>
                               </a>
                               <a href="${tweetItem.owner.url}">
                                <span class="nick">
                                   @${tweetItem.owner.username}
                                </span>
                               </a>
                                <span class="date">
                                    ${tweetItem.createdTime}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <p class="desc">${tweetItem.text}</p>
                    <ul class="btomcart_cmt-lk-bar">
                        <li class="comment">
                            <a href="${tweetItem.url}" target="_blank">
                                <span class="icon-comment"></span>
                            </a>
                        </li>
                        <li class="change">
                            <a href="${tweetItem.url}" target="_blank">
                                <span class="icon-retweet"></span>
                            </a>
                        </li>
                        <li class="like">
                            <a href="${tweetItem.url}" target="_blank">
                                <span class="icon-heart"></span>
                            </a>
                        </li>
                        <li class="del">
                            <a href="${tweetItem.url}" target="_blank">
                                <span class="icon-upload"></span>
                                <span>Del</span>
                            </a>
                        </li>
                    </ul>
                </div>`;
        return html;
    }
}

