import 'slick-carousel';

export default function () {
	let currentPage = 1;
    $(document).ready(function () {
        if ($('#aktuelt-slide').length > 0) {

            $('#aktuelt-slide .swiper-wrapper').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                arrows: false
            });
        }

        //detail slide
        if ($('.swipers-wrapper').length > 0) {
            $('.swipers-wrapper').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 575,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        }


        //Tab filter slide
        if ($('#tab-filter').length > 0) {
            $('#tab-filter .tab-list').slick({
                // slidesToShow: 5,
                slidesToScroll: 1,
                dots: false,
                infinite: false,
                arrows: false,
                variableWidth: true,
            });
        }


        // year fill
        $('.article-year-item a').click(function () {
            $('#text-search').val('');
			currentPage = 1;
            searchArticles();
        });

        //tab fill also for mobile
        $('.article__categories__item a').click(function () {
            var parent = $(this).parents('.slick-slide');
            parent.siblings().find('a').removeClass('active');
            $(this).addClass('active');
            $('#categoryFiltered').val($(this).data('category'));
            $('#text-search').val('');
			currentPage = 1;
            searchArticles()
        });

        //search fill
        $('#searchBtn').click(function (e) {
            resetArticleMenu();
			currentPage = 1;
            searchArticles();
            e.preventDefault();
            return false;
        });


        $('.load-more-article').click(function (e) {
			currentPage += 1;
            searchArticles();
			e.preventDefault();
            return false;
        });

        if ($('.aktuelt-module').length > 0) {
            loadArticlesPage();
        }
    });

    function loadArticlesPage(){
        var tag = getUrlParameter("tag");
        var year = getUrlParameter("year");
        if(tag!="" && tag != undefined){
            var $tagActive = $('.article__categories__item a[data-value="' + tag +'"]');
            if(year !="" && year != undefined){
                $('#article-year').text(year);
            }
            if($tagActive.length > 0){
                $tagActive.addClass("active")
                $tagActive.first().trigger('click');
            }
        }else{
            searchArticles();
        }
    }

    function resetArticleMenu() {
        $('.article__categories__item a').removeClass('active');
        $('.article__categories__item a[data-category=""]').addClass('active')
    }

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };


    function searchArticles() {
        var text = $('#text-search').val();
        var year = 0;
        var category = 0;
        if(text == ""){
            year = $('#article-year').text();
            category = $('#categoryFiltered').val();
        }
        var pageId = $('#pageId').val();

        showHideLoading(true);

        $.ajax({
            url: "/umbraco/surface/ArticlesSurface/Search",
            type: 'GET',
            data: {page: currentPage, text: text, year: year, category: category, CurrentPageId: pageId},
            dataType: 'json',
            success: function (data) {
                if (data != null) {
                    if (data.status == 200) {
                        var searchData = JSON.parse(data.searchResult);

                        var articles = searchData.result;
                        // console.log("articles", articles);

                        var listArticles = renderArticles(articles);
                        if (searchData.currentPage == 1) {
                            $('.list-aktuelt').html(listArticles);
                        } else {
                            $('.list-aktuelt').append(listArticles);
                        }
                        showHideLoadMore(searchData.currentPage < searchData.totapage);
                    } else {
                        console.log("Error when get data");
                    }
                }
                showHideLoading(false);
            }
        });
    }

    function showHideLoadMore(isShowed) {
        if (isShowed) {
            $('.load-more-article').show();
        } else {
            $('.load-more-article').hide();
        }
    }

    function showHideLoading(isShowed) {
        if (isShowed) {
            $('.articles-list-wr__list__loading').show();
        } else {
            $('.articles-list-wr__list__loading').hide();
        }
    }

    function renderArticles(articles) {
        var html = '';

        if (articles.length > 0) {
            for (var i = 0; i < articles.length; i++) {
                html += renderArticleItem(articles[i]);
            }
        } else {
            html = "<li class='row'><div class='col-12 empty-article'>Der er ingen resultater på den ønskede søgning.</div></li>";
        }

        return html;
    }

    function renderArticleItem(article) {
        var afText = article.author != ""?"af":"";
        var html = `<li class="row">
                        <div class="akt-item-image col-12 col-md-6 col-lg-6">
                            <a href="${article.url}" title="${article.altImage}">
                                <img src="${article.image}" alt="">
                            </a>
                        </div>
                        <div class="aktuelt__item__text col-12 col-md-6 col-lg-6">
                            <div class="date">${article.date}</div>
                            <div class="title">
                                <a href="${article.url}" title="${article.altImage}">${article.headline}
                                </a>
                            </div>
                            <div class="desc">
                                ${article.description}
                            </div>
                            <div class="author">
                                <a href="${article.tagUrl}" class="tag">${article.tag}</a>&nbsp;
                                <span class="author__of">${afText} </span>
                                <span class="author__name">${article.author}</span>
                            </div>
                        </div>
                    </li>`;
        return html;
    }


    //Show popup share
    $('.share-bar').on('click', function(e){
        e.stopPropagation();
        var $popup = $('#popupSocial');
        var top = $(this).offset().top - $popup.height() - 2;
        var right = $(this).offset().left + $(this).width() - $popup.width() + 2;
        $popup.css({
            'top': top,
            'left': right
        }).fadeIn();
    });
    $('body').on('click', function(){
        $('#popupSocial').hide();
    });

}