export default function() {
	$(document).ready(function(){
		if($('#cd-menu-button').length > 0) {
			$('#cd-menu-button').on('click', function(){
				toggleMenu();
			});
		};

	    function toggleMenu() {
			$(this).toggleClass('active');
			$('body').toggleClass('overflow-hidden');
			var CLASS_SHOW_MENU = 'active';
			var CLASS_OVERFLOW_HIDDEN = 'overflow-hidden';
	      	const $button = $('#cd-menu-button');
	      	const $menu = $('#cd-menu');
	        if ($menu.length > 0) {
		        if ($button.hasClass(CLASS_SHOW_MENU)) {
	              	$button.removeClass(CLASS_SHOW_MENU);
	                $('body').removeClass(CLASS_OVERFLOW_HIDDEN);
		        }else {
		        	$button.addClass(CLASS_SHOW_MENU);
	            	$button.addClass(CLASS_OVERFLOW_HIDDEN);
	            }
	        }
	    }

  		if($('#cd-menu .arrow').length > 0) {
			$('#cd-menu .arrow').each(function(){
				$(this).on('click', function(e){
					e.preventDefault();
					$(this).parents('li').toggleClass('show-sub');
				});
			});
		}
		if($(window).scrollTop() > 20){
			$('#header').addClass('fixed');
		}
		$(window, document).on('scroll', function(){
			if($(window).scrollTop() > 20){
				$('#header').addClass('fixed');
			}else {
				$('#header').removeClass('fixed');
			}
			var winScroll = $(window).scrollTop();
			var height = $(document).outerHeight() - $(window).outerHeight();
			var scrolled = (winScroll / height) * 100;
			$("#pr-bar").css('width', scrolled + "%");
		});


		if($('#mobile-footer-menu .arrow').length > 0) {
			$('#mobile-footer-menu .arrow').each(function(){
				$(this).on('click', function(e){
					e.preventDefault();
					$(this).parent('li').toggleClass('show-sub');
				});
			});
		}

		setTimeout(function() {
               $('.cc-revoke').css('display', 'block');
        }, 1000)

	});

}