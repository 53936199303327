export default function () {
	let currentPage = 1;
    $(document).ready(function () {

        //tab fill also for mobile //Tab filter slide
        $('.viden__categories__item a').click(function () {
            var parent = $(this).parents('.slick-slide');
            parent.siblings().find('a').removeClass('active');
            $(this).addClass('active');
            $('#categoryFiltered').val($(this).data('category'));
            $('#pageId').val(1);
            loadKnowledges()
        });

        $('.load-more-knowledges').click(function (e) {
            currentPage += 1;
            loadKnowledges();
			e.preventDefault();
            return false;
        });
        if ($('.viden-module').length > 0) {
            loadPageContent();
        }
    });

    function bindTagsEvent(){
        $(".tag-group__category").unbind();
        $(".tag-group__category").click(function(){
            var tag = $(this).data("category")
            var $tagActive = $('.viden__categories__item  a[data-value="' + tag +'"]');
            if($tagActive.length > 0){
                $tagActive.addClass("active")
                $tagActive.first().trigger('click');
            }
            return false;
        });
    }


    function loadPageContent(){
        var tag = getUrlParameter("tag");

        if(tag!="" && tag != undefined){
            var $tagActive = $('.viden__categories__item  a[data-value="' + tag +'"]');

            if($tagActive.length > 0){
                $tagActive.addClass("active")
                $tagActive.first().trigger('click');
            }
        }else {
            loadKnowledges();
        }
    }

    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    function loadKnowledges() {
        var category = $('#categoryFiltered').val();
        var pageId = $('#pageId').val();


        showHideLoading(true);

        $.ajax({
            url: "/umbraco/surface/Knowledges/GetKnowledges",
            type: 'GET',
            data: {page: currentPage, category: category, CurrentPageId: pageId},
            dataType: 'json',
            success: function (data) {
                if (data != null) {
                    if (data.status == 200) {
                        var searchData = JSON.parse(data.searchResult);

                        var knowledges = searchData.result;
                        // console.log("articles", articles);

                        var listKnowledges = renderKnowledges(knowledges);
                        if (searchData.currentPage == 1) {
                            $('.list-knowledge').html(listKnowledges);
                        } else {
                            $('.list-knowledge').append(listKnowledges);
                        }

                        showHideLoadMore(searchData.currentPage < searchData.totalPage);
                    } else {
                        console.log("Error when get data");
                    }
                }
                showHideLoading(false);
                bindTagsEvent();
            }
        });
    }

    function showHideLoadMore(isShowed) {
        if (isShowed) {
            $('.load-more-knowledges').show();
        } else {
            $('.load-more-knowledges').hide();
        }
    }

    function showHideLoading(isShowed) {
        if (isShowed) {
            $('.knowledges-list__loading').show();
        } else {
            $('.knowledges-list__loading').hide();
        }
    }

    function renderKnowledges(knowledges) {
        var html = '';

        if (knowledges.length > 0) {
            for (var i = 0; i < knowledges.length; i++) {
                html += renderKnowledgeItem(knowledges[i]);
            }
        } else {
            html = "<li class='row'><div class='empty-article'>No article found</div></li>";
        }

        return html;
    }

    function renderKnowledgeItem(knowledge) {
        var summary = "";
        if(knowledge.totalPage > 0){
            summary = knowledge.totalPage + " sider, " + knowledge.type;
        }

        var html = `<li class="row">
                <div class="akt-item-image col-12 col-md-6 col-lg-6">
                    <a href="${knowledge.url}" title="">
                        <img src="${knowledge.image}" target="${knowledge.target}" alt="">
                    </a>
                </div>
                <div class="aktuelt__item__text col-12 col-md-6 col-lg-6">
                    <div class="date">${knowledge.date}</div>
                    <div class="title">
                        <a href="${knowledge.url}" target="${knowledge.target}" title="">
                           ${knowledge.headline}
                        </a>
                    </div>
                    <div class="desc">
                        <a href="${knowledge.url}" title="">
                            ${knowledge.description}
                        </a>
                    </div>
                    <div class="tag-group author">
                        <a href="#" data-category="${knowledge.category}" class="tag-group__category tag">${knowledge.category}</a>&nbsp;
                        <span class="doc-info">${summary}</span>
                    </div>
                </div>
            </li>`;

        return html;
    }
}