import Chart from 'chart.js';
import chartjsPluginLabels from 'chartjs-plugin-labels';

import chartDoughnutAll from "components/maerkesager/chart/chart-doughnut-all";
import chartBar from "components/maerkesager/chart/chart-bar";
import chartLine from "components/maerkesager/chart/chart-line";
import chartPie from "components/maerkesager/chart/chart-pie";
export default function () {
   chartDoughnutAll();
   chartBar();
   chartLine();
   chartPie();
}