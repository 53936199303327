export default function pieChart(){
    function hexToRgbA(hex){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            // return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
            return [(c>>16)&255, (c>>8)&255, c&255].join(',');
        }
        // throw new Error('Bad Hex');
        return [(c>>16)&0, (c>>8)&0, c&0].join(',');
    };
	$(document).ready(function(){
		if($('.pie-chart').length > 0) {
			var chartData = JSON.parse($('.pie-chart .chart-data').val());
			var placeHolder = document.getElementById('pieChart').getContext('2d');
            function editArr (arrs, param) {
            	var newColor = [];
            	arrs.forEach(function callbacks(element, index){
            		newColor[index] = 'rgba(' + hexToRgbA(element) + ',' + param + ')';
            	});
            	return newColor;
            };
            function buildDataset(arrObject) {
                arrObject.forEach(function callbacks(object, index) {
                    object.backgroundColor = editArr(object.borderColor, 0.9);
                    // console.log("object.borderColor", object.borderColor);
                    object.borderColor = editArr(object.borderColor, 1);
                });
                return arrObject;
            }
            var lastDataset = buildDataset(chartData.data.datasets);
            chartData.data.datasets = lastDataset;
			var cData = chartData.data;
			var cOptions = {
				legend: {
					display: false,
				},
				// PLUGIN CREATE LABEL INSIDE EACH PEACE use chartjs-plugin-label
				tooltips: {
					enabled: false,
					titleFontSize: 14,
					titleFontColor: '#01BEE0',
					bodyFontSize: 12,
					displayColors: false,
					callbacks: {
						title: function(tooltipItem, data) {
						  return data['labels'][tooltipItem[0]['index']];
						},
						label: function(tooltipItem, data) {
							let datasetNumber = tooltipItem.datasetIndex;
							let curVal = data.datasets[datasetNumber].data[tooltipItem['index']];
							let reducerCalback = (sum, currentVal)=>parseInt(sum) + parseInt(currentVal);
							let totalVal = data.datasets[datasetNumber].data.reduce(reducerCalback);
							let curText = data.datasets[datasetNumber]['tooltipText'][tooltipItem['index']] === undefined ? '' : data.datasets[datasetNumber]['tooltipText'][tooltipItem['index']];

						  // return Math.round((curVal / totalVal)*100) + '% ' + curText;
						  return curText;
						},
					},
					custom: function(tooltipModel) {
						var tooltipEl = document.getElementById('pie-chart-tooltip2');
						var tooltipCaret = document.querySelectorAll('#pie-chart-tooltip2 .caret')[0];
						// Create element on first render
		                if (!tooltipEl) {
		                    tooltipEl = document.createElement('div');
		                    tooltipEl.id = 'pie-chart-tooltip2';
		                    tooltipEl.classList.add('chartjs-tooltip');
		                    tooltipEl.innerHTML = '<table width="100%"></table><div class="caret"></div>';
		                    document.getElementById("pieChart").parentElement.appendChild(tooltipEl);
		                    tooltipCaret = document.querySelectorAll('#pie-chart-tooltip2 .caret')[0];
		                }
		                // Hide if no tooltip
		                if (tooltipModel.opacity === 0) {
		                    tooltipEl.style.opacity = 0;
		                    return;
		                }

		                
		                function getBody(bodyItem) {
		                    return bodyItem.lines;
		                }
		                // Set Text
		                if (tooltipModel.body) {
		                    var titleLines = tooltipModel.title || [];
		                    var bodyLines = tooltipModel.body.map(getBody);

		                    var innerHtml = '<thead>';

		                    titleLines.forEach(function(title, i) {
		                        var colors = tooltipModel.labelColors[i];
		                        // console.log("colors", colors);
		                        var span = '<span style="color:' + colors.borderColor + '">';
		                        innerHtml += '<tr><th>' + span + title + '</span></th></tr>';
		                    });
		                    innerHtml += '</thead><tbody>';

		                    bodyLines.forEach(function(body) {
		                        innerHtml += '<tr><td>' + body + '</td></tr>';
		                    });
		                    innerHtml += '</tbody>';

		                    var tableRoot = tooltipEl.querySelector('table');
		                    tableRoot.innerHTML = innerHtml;
		                }


		                //re position label with length larger than chart width
		                if(tooltipModel.width > 200){
		                    tooltipModel.width = 200;
		                    var distanceX = tooltipModel.caretX - 200 + 20;
		                    var distanceY = tooltipModel.caretY - tooltipEl.offsetHeight + 5;
		                    if(distanceX >= 0) {
		                        tooltipModel.x = distanceX;
		                        tooltipModel.xAlign ='right';
		                    }else {
		                        tooltipModel.x = tooltipModel.caretX - 20;
		                        tooltipModel.xAlign ='left';
		                    }
		                    if(distanceY >= 0) {
		                        tooltipModel.y = distanceY;
		                        tooltipModel.yAlign ='bottom';
		                    }else {
		                        tooltipModel.y = tooltipModel.caretY + 5;
		                        tooltipModel.xAlign ='top';
		                    }
		                }
		                // Set caret direction
		                tooltipEl.classList.remove('center', 'middle', 'left', 'top', 'right', 'bottom');
		                if (tooltipModel.xAlign) {
		                    tooltipEl.classList.add(tooltipModel.xAlign);
		                } else {
		                    tooltipEl.classList.add('center');
		                }
		                if (tooltipModel.yAlign){
		                    if (tooltipModel.yAlign == 'center') {
		                        tooltipModel.yAlign =='middle';
		                    }else {
		                        tooltipEl.classList.add(tooltipModel.yAlign);
		                    }
		                }else {
		                    tooltipEl.classList.add('middle');
		                }


		                // `this` will be the overall tooltip
		                var position = this._chart.canvas.getBoundingClientRect();

		                // Display, position, and set styles for font
		                tooltipEl.style.opacity = 1;
		                tooltipCaret.style.opacity = 1;
		                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
		                tooltipEl.style.pointerEvents = 'none';
		                
		                tooltipEl.style.left = tooltipModel.x + 'px';
		                tooltipEl.style.top = tooltipModel.y + 'px';

		                tooltipCaret.style.left = tooltipModel.caretX - tooltipModel.x + 'px';
		                tooltipCaret.style.top = tooltipModel.caretY - tooltipModel.y + 'px';
					}
				},
				plugins: {
					labels:{
						render: 'percentage',
						fontSize: 16,
						fontStyle: 'bold',
						fontColor: '#fff',
						overlap: true,
						showActualPercentages: true,
					} 
				}

			};
			var pieChart = new Chart(placeHolder, {
			    type: chartData.type,
			    data: cData,
			    // data: {
			    //     datasets: [{
			    //         backgroundColor: [
			    //             'rgba(255, 99, 132, 0.2)',
			    //             'rgba(54, 162, 235, 0.2)',
			    //             'rgba(255, 206, 86, 0.2)',
			    //             'rgba(75, 192, 192, 0.2)',
			    //             'rgba(153, 102, 255, 0.2)',
			    //             'rgba(255, 159, 64, 0.2)'
			    //         ],
			    //         borderColor: [
			    //             'rgba(255, 99, 132, 1)',
			    //             'rgba(54, 162, 235, 1)',
			    //             'rgba(255, 206, 86, 1)',
			    //             'rgba(75, 192, 192, 1)',
			    //             'rgba(153, 102, 255, 1)',
			    //             'rgba(255, 159, 64, 1)'
			    //         ],
			    //         borderWidth: 1
			    //     }]
			    // },
			    options: cOptions,
			});
		}
	});
}