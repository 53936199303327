// import fitty from 'Plugins/fitty/dist/fitty.min.js';
export default function doughnutChart(){
    function hexToRgbA(hex){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            // return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
            return [(c>>16)&255, (c>>8)&255, c&255].join(',');
        }
        // throw new Error('Bad Hex');
        return [(c>>16)&0, (c>>8)&0, c&0].join(',');
    };

    function setChartFont(selector, fontSize, width) {
        setFontSizeFolowLength();
        setFontResizeFolowContainer();

        function setFontSizeFolowLength() {
            const newTextLength = $(selector).width();
            var fontRatio = newTextLength/width > 1 ? newTextLength/width : 1;
            var newFontSize = fontSize / fontRatio;

            $(selector).css('font-size', newFontSize + 'px');
        }
        function setFontResizeFolowContainer() {
            const fontSize = parseInt($(selector).css('font-size').replace('px', ""));
            // const width = parseInt($(selector).parent().css('width').replace('px', ""));
            $(window).on('load resize', function(){
                var newWidth = parseInt($(selector).parent().css('width').replace('px', ""));
                var fontRatio = fontSize/width;
                var newFontSize = newWidth * fontRatio;
                $(selector).css('font-size', newFontSize + 'px');
            });
        }
    }
    function setChartFontMultiLine(selector, fontSize, width, height) {
      setFontSizeFolowLengthMultiLine(selector, fontSize);
      setFontResizeFolowContainer();
	    function setFontSizeFolowLengthMultiLine(selector, fontSize) {
	        // const fontSize = 60;
	        // var initContainHeight = $(selector).parent().height();
	        // var initContainWidth = $(selector).parent().width();
	        var initContainHeight = width;
	        var initContainWidth = height;

	        $(selector).css({'display' : 'inline-block', 'white-space': 'nowrap'});
	        const newTextLength = $(selector).width();
	        var fontRatioX = newTextLength/initContainWidth; // also be line number
	        var newFontSize = fontSize / fontRatioX;
	        
	        var newLineNum = fontRatioX > 1 ? parseInt(fontRatioX) + 1 : 1;
	        if(newLineNum > 1) {
	        	var fontSizeWrap = newFontSize * newLineNum;
	        	var textHeight = fontSizeWrap * newLineNum;
	            while(textHeight > initContainHeight) {
	                fontSizeWrap = fontSizeWrap - 0.5;
	                textHeight = fontSizeWrap * newLineNum;
	            }
	            newFontSize = fontSizeWrap;
	        }
	        $(selector).css({'display' : 'unset', 'white-space': 'unset'});
	        $(selector).css('font-size', newFontSize + 'px');
	    }

      function setFontResizeFolowContainer() {
          const fontSize = parseInt($(selector).css('font-size').replace('px', ""));
          $(window).on('load resize', function(){
              var newWidth = parseInt($(selector).parent().css('width').replace('px', ""));
              var fontRatio = fontSize/width;
              var newFontSize = newWidth * fontRatio;
              $(selector).css('font-size', newFontSize + 'px');
          });
      }
    }

	$(document).ready(function(){
		if($('.donut-chart').length > 0) {
            function editArr (arrs, param) {
            	var newColor = [];
            	arrs.forEach(function callbacks(element, index){
            		newColor[index] = 'rgba(' + hexToRgbA(element) + ',' + param + ')';
            	});
            	return newColor;
            };
            function buildDataset(arrObject) {
                arrObject.forEach(function callbacks(object, index) {
                    // object.borderColor = editArr(object.borderColor, 1);
                    object.backgroundColor = editArr(object.backgroundColor, 0.9);
                    // console.log("object.backgroundColor", object.backgroundColor);
                });
                return arrObject;
            }

			if($('#doughnutChart2').length > 0) {
				var genData = JSON.parse($('#doughnutChart2').siblings('.chart-data').val());
	            genData.data.datasets = buildDataset(genData.data.datasets);
				var cData = genData.data;
				var placeHolder = document.getElementById('doughnutChart2').getContext('2d');
				var cOptions = {
					legend: {
						display: false,
					},
					// // PLUGIN CREATE LABEL INSIDE EACH PEACE use chartjs-plugin-label
					tooltips: {
						enabled: false,
						titleFontSize: 14,
						titleFontColor: '#01BEE0',
						bodyFontSize: 12,
						displayColors: false,
						callbacks: {
							title: function(tooltipItem, data) {
							  return data['labels'][tooltipItem[0]['index']];
							},
							label: function(tooltipItem, data) {
								let curVal = data.datasets[0].data[tooltipItem['index']];
								let reducerCalback = (sum, currentVal)=>sum + currentVal;
								let totalVal = data.datasets[0].data.reduce(reducerCalback);
								let curText = data.datasets[0]['tooltipText'][tooltipItem['index']] === undefined ? '' : data.datasets[0]['tooltipText'][tooltipItem['index']];

							  return curText;
							},
						},
						custom: function(tooltipModel) {
							var tooltipEl = document.getElementById('doughnutChart-tooltip2');
							var tooltipCaret = document.querySelectorAll('#doughnutChart-tooltip2 .caret')[0];
							// Create element on first render
			                if (!tooltipEl) {
			                    tooltipEl = document.createElement('div');
			                    tooltipEl.id = 'doughnutChart-tooltip2';
			                    tooltipEl.classList.add('chartjs-tooltip');
			                    tooltipEl.innerHTML = '<table width="100%"></table><div class="caret"></div>';
			                    document.getElementById("doughnutChart2").parentElement.appendChild(tooltipEl);
			                    tooltipCaret = document.querySelectorAll('#doughnutChart-tooltip2 .caret')[0];
			                }
			                // Hide if no tooltip
			                if (tooltipModel.opacity === 0) {
			                    tooltipEl.style.opacity = 0;
			                    return;
			                }

			                
			                function getBody(bodyItem) {
			                    return bodyItem.lines;
			                }
			                // Set Text
			                if (tooltipModel.body) {
			                    var titleLines = tooltipModel.title || [];
			                    var bodyLines = tooltipModel.body.map(getBody);

			                    var innerHtml = '<thead>';

			                    titleLines.forEach(function(title, i) {
			                        var colors = tooltipModel.labelColors[i];
			                        // console.log("colors", colors);
			                        var span = '<span style="color:' + colors.borderColor + '">';
			                        innerHtml += '<tr><th>' + span + title + '</span></th></tr>';
			                    });
			                    innerHtml += '</thead><tbody>';

			                    bodyLines.forEach(function(body) {
			                        innerHtml += '<tr><td>' + body + '</td></tr>';
			                    });
			                    innerHtml += '</tbody>';

			                    var tableRoot = tooltipEl.querySelector('table');
			                    tableRoot.innerHTML = innerHtml;
			                }


			                //re position label with length larger than chart width
			                if(tooltipModel.width > 200){
			                    tooltipModel.width = 200;
			                    var distanceX = tooltipModel.caretX - 200 + 20;
			                    var distanceY = tooltipModel.caretY - tooltipEl.offsetHeight + 5;
			                    if(distanceX >= 0) {
			                        tooltipModel.x = distanceX;
			                        tooltipModel.xAlign ='right';
			                    }else {
			                        tooltipModel.x = tooltipModel.caretX - 20;
			                        tooltipModel.xAlign ='left';
			                    }
			                    if(distanceY >= 0) {
			                        tooltipModel.y = distanceY;
			                        tooltipModel.yAlign ='bottom';
			                    }else {
			                        tooltipModel.y = tooltipModel.caretY + 5;
			                        tooltipModel.xAlign ='top';
			                    }
			                }
			                // Set caret direction
			                tooltipEl.classList.remove('center', 'middle', 'left', 'top', 'right', 'bottom');
			                if (tooltipModel.xAlign) {
			                    tooltipEl.classList.add(tooltipModel.xAlign);
			                } else {
			                    tooltipEl.classList.add('center');
			                }
			                if (tooltipModel.yAlign){
			                    if (tooltipModel.yAlign == 'center') {
			                        tooltipModel.yAlign =='middle';
			                    }else {
			                        tooltipEl.classList.add(tooltipModel.yAlign);
			                    }
			                }else {
			                    tooltipEl.classList.add('middle');
			                }


			                // `this` will be the overall tooltip
			                var position = this._chart.canvas.getBoundingClientRect();

			                // Display, position, and set styles for font
			                tooltipEl.style.opacity = 1;
			                tooltipCaret.style.opacity = 1;
			                tooltipEl.style.left = tooltipModel.x + 'px';
			                tooltipEl.style.top = tooltipModel.y + 'px';
			                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
			                tooltipEl.style.pointerEvents = 'none';
			                
			                tooltipCaret.style.left = tooltipModel.caretX - tooltipModel.x + 'px';
			                tooltipCaret.style.top = tooltipModel.caretY - tooltipModel.y + 'px';
						}
					},
					plugins: {
						labels:{
							render: function(){},
						} 
					}
				};
				
				var doughnutChart2 = new Chart(placeHolder, {
				    type: genData.type,
				    data: cData,
				    options: cOptions,

				    //PLUGIN CREATE TITLE INSIDE CHART WITH HTML
				    plugins: [{
				    	beforeDraw: function(obChart) {
				    		// let titleCenter = document.getElementById('inside-title2');
				    		let titleCenter = $('#doughnutChart2').siblings('.inside-title2').get(0);
				    		titleCenter.style.width = obChart['innerRadius']*2 + 'px';
				    		titleCenter.style.height = obChart['innerRadius']*2 + 'px';
				    		titleCenter.style.left = obChart.outerRadius - obChart.innerRadius + (obChart.width/2 - obChart.outerRadius) + 'px';
				    		titleCenter.style.top = obChart.outerRadius - obChart.innerRadius + obChart['legend']['height'] + 'px';
				    	}
				    }]
				});
				// fitty('.inside-title2 .center-title', {minSize: 12, maxSize: 90});
				// fitty('.inside-title2 .sub', {minSize: 8, maxSize: 30});
			    setChartFont('.inside-title2 .center-title', 90, 162);
			    setChartFont('.inside-title2 .center-sub', 18, 162);
			}
			if($('#doughnutChart').length > 0) {
				var genData = JSON.parse($('#doughnutChart').siblings('.chart-data').val());
	            genData.data.datasets = buildDataset(genData.data.datasets);
				// console.log("genData.data.datasets.textColor", genData.data.datasets[0].textColor);

				var cData = genData.data;
				var placeHolder = document.getElementById('doughnutChart').getContext('2d');
				var cOptions = {
					legend: {
						align: 'start',
	                    labels: {
	                        boxWidth: 12,
	                        fontSize: 14,
	                        padding: 20,
	                        usePointStyle: true,
	                    }
					},
					legendCallback: function(chart){
						// console.log(chart);
						// chart.legend.height = chart.legend.height + 200;
						// chart.chartArea.top = chart.chartArea.top + 20;
					},
					cutoutPercentage: 60,
					// // PLUGIN CREATE LABEL INSIDE EACH PEACE use chartjs-plugin-label
					tooltips: {
						enabled: false,
						titleFontSize: 14,
						titleFontColor: '#01BEE0',
						bodyFontColor: '#ffffff',
						bodyFontSize: 12,
						displayColors: false,
						callbacks: {
							title: function(tooltipItem, data) {
							  return data['labels'][tooltipItem[0]['index']];
							},
							label: function(tooltipItem, data) {
								let datasetNumber = tooltipItem.datasetIndex;
								let curVal = data.datasets[datasetNumber].data[tooltipItem['index']];
								let reducerCalback = (sum, currentVal)=>parseInt(sum) + parseInt(currentVal);
								let totalVal = data.datasets[datasetNumber].data.reduce(reducerCalback);
								let curText = data.datasets[datasetNumber]['tooltipText'][tooltipItem['index']] ===undefined ? '' : data.datasets[datasetNumber]['tooltipText'][tooltipItem['index']];

							  // return Math.round((curVal / totalVal)*100) + '% ' + curText;
							  return curText;
							},
						},
						custom: function(tooltipModel) {
							var tooltipEl = document.getElementById('doughnutChart-tooltip');
							var tooltipCaret = document.querySelectorAll('#doughnutChart-tooltip .caret')[0];
							// Create element on first render
			                if (!tooltipEl) {
			                    tooltipEl = document.createElement('div');
			                    tooltipEl.id = 'doughnutChart-tooltip';
			                    tooltipEl.classList.add('chartjs-tooltip');
			                    tooltipEl.innerHTML = '<table width="100%"></table><div class="caret"></div>';
			                    document.getElementById("doughnutChart").parentElement.appendChild(tooltipEl);
			                    tooltipCaret = document.querySelectorAll('#doughnutChart-tooltip .caret')[0];
			                }
			                // Hide if no tooltip
			                if (tooltipModel.opacity === 0) {
			                    tooltipEl.style.opacity = 0;
			                    tooltipCaret.style.opacity = 0;
			                    return;
			                }

			                
			                function getBody(bodyItem) {
			                    return bodyItem.lines;
			                }
			                // Set Text
			                if (tooltipModel.body) {
			                    var titleLines = tooltipModel.title || [];
			                    var bodyLines = tooltipModel.body.map(getBody);

			                    var innerHtml = '<thead>';

			                    titleLines.forEach(function(title, i) {
			                        var colors = tooltipModel.labelColors[i];
			                        // console.log("colors", colors);
			                        var span = '<span style="color:' + colors.borderColor + '">';
			                        innerHtml += '<tr><th>' + span + title + '</span></th></tr>';
			                    });
			                    innerHtml += '</thead><tbody>';

			                    bodyLines.forEach(function(body) {
			                        innerHtml += '<tr><td>' + body + '</td></tr>';
			                    });
			                    innerHtml += '</tbody>';

			                    var tableRoot = tooltipEl.querySelector('table');
			                    tableRoot.innerHTML = innerHtml;
			                }


			                //re position label with length larger than chart width
			                if(tooltipModel.width > 200){
			                    tooltipModel.width = 200;
			                    var distanceX = tooltipModel.caretX - 200 + 20;
			                    var distanceY = tooltipModel.caretY - tooltipEl.offsetHeight + 5;
			                    if(distanceX >= 0) {
			                        tooltipModel.x = distanceX;
			                        tooltipModel.xAlign ='right';
			                    }else {
			                        tooltipModel.x = tooltipModel.caretX - 20;
			                        tooltipModel.xAlign ='left';
			                    }
			                    if(distanceY >= 0) {
			                        tooltipModel.y = distanceY;
			                        tooltipModel.yAlign ='bottom';
			                    }else {
			                        tooltipModel.y = tooltipModel.caretY + 5;
			                        tooltipModel.xAlign ='top';
			                    }
			                }
			                // Set caret direction
			                tooltipEl.classList.remove('center', 'middle', 'left', 'top', 'right', 'bottom');
			                if (tooltipModel.xAlign) {
			                    tooltipEl.classList.add(tooltipModel.xAlign);
			                } else {
			                    tooltipEl.classList.add('center');
			                }
			                if (tooltipModel.yAlign){
			                    if (tooltipModel.yAlign == 'center') {
			                        tooltipModel.yAlign =='middle';
			                    }else {
			                        tooltipEl.classList.add(tooltipModel.yAlign);
			                    }
			                }else {
			                    tooltipEl.classList.add('middle');
			                }


			                // `this` will be the overall tooltip
			                var position = this._chart.canvas.getBoundingClientRect();

			                // Display, position, and set styles for font
			                tooltipEl.style.opacity = 1;
			                tooltipCaret.style.opacity = 1;
			                tooltipEl.style.left = tooltipModel.x + 'px';
			                tooltipEl.style.top = tooltipModel.y + 'px';
			                tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
			                tooltipEl.style.pointerEvents = 'none';
			                
			                tooltipCaret.style.left = tooltipModel.caretX - tooltipModel.x + 'px';
			                tooltipCaret.style.top = tooltipModel.caretY - tooltipModel.y + 'px';
						}
					},
					plugins: {
						labels:{
							render: 'percentage',
							fontSize: 16,
							fontStyle: 'bold',
							// fontColor: ['#ffffff','#ffffff','#CA920B','#ffffff'],
							fontColor: genData.data.datasets[0].textColor,
							overlap: true,
							showActualPercentages: true,
						} 
					}
				};
				
				var doughnutChart = new Chart(placeHolder, {
				    type: genData.type,
				    data: cData,
					options: cOptions,
				    //PLUGIN CREATE TITLE INSIDE CHART WITH HTML
				    plugins: [{
				    	beforeDraw: function(obChart) {
				    		let titleCenter = $('#doughnutChart').siblings('.inside-title').get(0);
				    		titleCenter.style.width = obChart['innerRadius']*2 + 'px';
				    		titleCenter.style.height = obChart['innerRadius']*2 + 'px';
				    		titleCenter.style.left = obChart.outerRadius - obChart.innerRadius + (obChart.width/2 - obChart.outerRadius) + 'px';
				    		titleCenter.style.top = obChart.outerRadius - obChart.innerRadius + obChart['legend']['height'] + 'px';
				    		// titleCenter.style.left = obChart['legend']['height']/2 + obChart['innerRadius'] + 'px';
				    		// titleCenter.style.top = obChart['innerRadius'] + obChart['legend']['height'] + 'px';
				    	},
				    	afterDatasetDraw : function() {
				    	}
				    }]
				});
				doughnutChart.generateLegend();
				// fitty('.inside-title .center-title', {minSize: 18, maxSize: 160, multiLine: true});
				setChartFontMultiLine('.inside-title .center-title', 25, 180, 180);
			}
		}
	});
}